<template>
  <v-app>
    <tev-navbar backTo="/compliance/properties" title="Properties" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <div class="text-xs-center pa-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <v-form ref="form" v-if="!isLoading">
            <v-container>
              <v-layout row wrap>
                <v-flex>
                  <span class="grey--text">Management Company</span>
                  <h2 class="mb-4">{{subscriberName}}</h2>
                </v-flex>
                <!--v-flex text-xs-right hidden-xs-only>
                  <v-btn
                    color="primary"
                    large
                    depressed
                    @click="showUpload=true"
                  >Upload Document</v-btn>
                </v-flex-->
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>

      <v-layout pt-4 row wrap>
        <v-flex xs12 mb-3>
          <h4 class="ma-0">DOCUMENTS</h4>
          <hr class="v-divider theme--light">
        </v-flex>

        <v-flex xs12 mt-2 mb-3>
          <v-layout row wrap v-for="(item, index) in documents" v-bind:key="index">
            <v-flex xs12 sm6 class="pr-3">
              <v-text-field
                browser-autocomplete="off"
                v-model="item.description"
                label="Description"
                :rules="rules.required"
                :disabled="item.id!=null"
                required
                box
              ></v-text-field>
            </v-flex>

            <template v-if="item.id">
              <v-flex xs6>
                <v-btn flat small outline color="primary" @click="viewDoc(index)">View</v-btn>
                <v-btn flat small outline color="primary" @click="removeDoc(index)">Remove</v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex xs6>
                <UploadButton
                  :color="item.url?'black':'primary'"
                  labelClass="white--text"
                  large
                  depressed
                  :disabled="item.description==''"
                  v-bind:title="item.url?'View':'Upload File'"
                  v-bind:multiple="false"
                  v-bind:done="uploadDoc"
                ></UploadButton>
              </v-flex>
            </template>
          </v-layout>
          <v-flex xs12>
            <div v-ripple class="d-inline-block pr-3 rounded" @click="addDocument">
              <v-btn color="primary" fab small dark depressed>
                <v-icon>add</v-icon>
              </v-btn>
              <a class="ml-1">Add document</a>
            </div>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-content>

    <v-dialog v-model="showDialog" :fullscreen="true">
      <v-card class="dialogViewer">
        <v-toolbar fixed>
          <v-toolbar-title>File Preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <transition name="fade">
          <iframe :src="view.url" v-if="view"></iframe>
        </transition>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showUpload" width="600">
      <v-card>
        <v-card-title class="headline grey white--text" primary-title>
          <span>Upload document</span>
          <v-spacer />
          <v-btn icon color="white" flat @click="showUpload = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>Please enter document description</v-card-text>
        <v-layout row wrap mb-5>
          <v-flex xs12 sm6 class="pr-3">
            <v-text-field
              browser-autocomplete="off"
              v-model="description"
              label="Description"
              :rules="rules.required"
              required
              box
            ></v-text-field>
          </v-flex>

          <v-flex xs12 pa-2 v-if="!disablePricingInformation">
            <UploadButton
              large
              depressed
              v-bind:title="'Upload'"
              v-bind:multiple="false"
              v-bind:done="uploadFile"
              v-if="!isUploading"
            ></UploadButton>
          </v-flex>
        </v-layout>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            :loading="isUploading"
            large
            depressed
            v-bind:class="{'blue--text': isUploading, 'white--text': !isUploading}"
            :disabled="isUploading"
            @click="sendDocument"
            v-text="'Submit'"
          ></v-btn>
          <v-btn
            color="blue"
            outline
            large
            depressed
            :disabled="isUploading"
            @click="showUpload=false"
            v-text="'No'"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showDelete" width="750">
        <v-card>
            <v-card-text class="pa-5">
                <h2 class="text-xs-center">Are you sure you want to delete this document?</h2>
                <h4 class="text-xs-center grey--text" v-if="view">{{ view.description }}</h4>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click="showDelete = false">No</v-btn>
                <v-btn outline color="primary" flat @click="deleteDoc">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import Validator from "../../libs/validator";
import UploadButton from "../../components/UploadButton";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  data() {
    const validator = new Validator();
    return {
      isUploading: false,
      isLoading: false,
      showUpload: false,
      showDelete: false,
      showDialog: false,

      documents: [],
      description: null,
      uploadFile: null,

      propertyId: null,

      subscriberId: null,
      subscriberName: null,
      propertyName: null,


      proposalDocUrl: null,
      proposalDoc: null,

      rules: {
        required: [validator.rules.required("This field is required.")],
        email: [
          validator.rules.required("E-mail is required."),
          validator.rules.email("Invalid email.")
        ]
      }
    };
  },
  watch: {
    showDialog(val) {
      if (!val) {
        const _this = this;
        setTimeout(function() {
          _this.view = null;
        }, 300);
      }
    }
  },
  components: {
    UploadButton
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    }),
    rushFeeDue() {
      return this.rushFee == null ? null : this.rushFee - this.rushFeeRetained;
    },
    salesRepName () {
      const found = this.salesreps.find(element => element.id == this.salesRepId)
      return (found) ? found.description : ""
    }
  },
  created() {
    this.checkCurrentLogin();
  },
  updated() {
    this.checkCurrentLogin();
  },
  methods: {
    addDocument() {
      this.documents.push({
        id: null,
        description: "",
        content: null,
        url: null,
        mimeType: null,
      })
    },
    viewDoc(index) {
      this.view = this.documents[index];
      this.showDialog = true;
    },
    removeDoc(index) {
      this.view = this.documents[index];
      this.showDelete = true;
    },
    deleteDoc() {
      const _this = this;
      this.$http
        .delete("/manager/property/deleteFile/" + this.view.id)
        .then(response => {
          _this.loadDocuments(_this.propertyId)
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError(
            e.error || "An error occurred while processing your request."
          );
        })
        .finally(() => {
          _this.showDelete = false;
          _this.isUploading = false;
        });
    },
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (
        !this.currentUser ||
        !this.currentUser.isLoggedIn ||
        !localStorage.token ||
        now >= this.currentUser.expire
      ) {
        this.$router.push("/login?redirect=" + this.$route.path);
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    },
    uploadDoc(e) {
      const _this = this;
      const ndocs = this.documents.length;
      this.description = this.documents[ndocs-1].description;

      this.isUploading = true;
      this.$http
        .put("/manager/property/uploadFile", {
          propertyId: this.propertyId,
          description: this.description,
          content: e.data,
          mimeType: e.type
        })
        .then(response => {
          // eslint-disable-next-line
          _this.loadDocuments(_this.propertyId)
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError(
            e.error || "An error occurred while processing your request."
          );
        })
        .finally(() => {
          _this.showUpload = false;
          _this.isUploading = false;
        });

      //this.proposalDoc = e.data;
      //this.proposalDocUrl = e.name;
    },
    alertSuccess(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "green"
      });
    },
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    loadPropertyInfo(id) {
      const _this = this;
      this.$http
        .get("/manager/properties/invitation/" + id)
        .then(response => {
          _this.subscriberName = response.data["subscriberName"];
          _this.propertyName = response.data["propertyName"];
          _this.propertyId = response.data["propertyId"];
          console.log(_this.propertyId)

        });
    },
    loadDocuments(id) {
      const _this = this;
      //this.isLoading = true;
      this.$http
        .get("/manager/propertyFiles/" + id)
        .then(response => {
          console.log("response", response.data)
          this.documents = response.data.files;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError(
            e.error || "An error occurred while processing your request."
          );
        })
        .finally(() => {
          console.log("finally")
          _this.isLoading = false;
        });
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadPropertyInfo(this.$route.params.id);
      this.loadDocuments(this.$route.params.id);
    }
  }
};
</script>
<style>
.v-dialog .v-card__text {
  max-height: 300px;
  overflow: scroll;
}

.blue--line {
  border-bottom: 2px solid #2196f3;
}

.dialogViewer {
  height: 100%;
  padding-top: 64px;
}

.dialogViewer iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
</style>
