var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", {
        attrs: { backTo: "/compliance/properties", title: "Properties" },
      }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", [
                                    _c("span", { staticClass: "grey--text" }, [
                                      _vm._v("Management Company"),
                                    ]),
                                    _c("h2", { staticClass: "mb-4" }, [
                                      _vm._v(_vm._s(_vm.subscriberName)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { "pt-4": "", row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                _c("h4", { staticClass: "ma-0" }, [_vm._v("DOCUMENTS")]),
                _c("hr", { staticClass: "v-divider theme--light" }),
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "", "mb-3": "" } },
                [
                  _vm._l(_vm.documents, function (item, index) {
                    return _c(
                      "v-layout",
                      { key: index, attrs: { row: "", wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "pr-3", attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Description",
                                rules: _vm.rules.required,
                                disabled: item.id != null,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: item.description,
                                callback: function ($$v) {
                                  _vm.$set(item, "description", $$v)
                                },
                                expression: "item.description",
                              },
                            }),
                          ],
                          1
                        ),
                        item.id
                          ? [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        flat: "",
                                        small: "",
                                        outline: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDoc(index)
                                        },
                                      },
                                    },
                                    [_vm._v("View")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        flat: "",
                                        small: "",
                                        outline: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeDoc(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Remove")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("UploadButton", {
                                    attrs: {
                                      color: item.url ? "black" : "primary",
                                      labelClass: "white--text",
                                      large: "",
                                      depressed: "",
                                      disabled: item.description == "",
                                      title: item.url ? "View" : "Upload File",
                                      multiple: false,
                                      done: _vm.uploadDoc,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-inline-block pr-3 rounded",
                        on: { click: _vm.addDocument },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              fab: "",
                              small: "",
                              dark: "",
                              depressed: "",
                            },
                          },
                          [_c("v-icon", [_vm._v("add")])],
                          1
                        ),
                        _c("a", { staticClass: "ml-1" }, [
                          _vm._v("Add document"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: true },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogViewer" },
            [
              _c(
                "v-toolbar",
                { attrs: { fixed: "" } },
                [
                  _c("v-toolbar-title", [_vm._v("File Preview")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.view
                  ? _c("iframe", { attrs: { src: _vm.view.url } })
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showUpload,
            callback: function ($$v) {
              _vm.showUpload = $$v
            },
            expression: "showUpload",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey white--text",
                  attrs: { "primary-title": "" },
                },
                [
                  _c("span", [_vm._v("Upload document")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "white", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showUpload = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [_vm._v("Please enter document description")]),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "mb-5": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pr-3", attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          label: "Description",
                          rules: _vm.rules.required,
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.disablePricingInformation
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", "pa-2": "" } },
                        [
                          !_vm.isUploading
                            ? _c("UploadButton", {
                                attrs: {
                                  large: "",
                                  depressed: "",
                                  title: "Upload",
                                  multiple: false,
                                  done: _vm.uploadFile,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("v-btn", {
                    class: {
                      "blue--text": _vm.isUploading,
                      "white--text": !_vm.isUploading,
                    },
                    attrs: {
                      color: "blue",
                      loading: _vm.isUploading,
                      large: "",
                      depressed: "",
                      disabled: _vm.isUploading,
                    },
                    domProps: { textContent: _vm._s("Submit") },
                    on: { click: _vm.sendDocument },
                  }),
                  _c("v-btn", {
                    attrs: {
                      color: "blue",
                      outline: "",
                      large: "",
                      depressed: "",
                      disabled: _vm.isUploading,
                    },
                    domProps: { textContent: _vm._s("No") },
                    on: {
                      click: function ($event) {
                        _vm.showUpload = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "750" },
          model: {
            value: _vm.showDelete,
            callback: function ($$v) {
              _vm.showDelete = $$v
            },
            expression: "showDelete",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "pa-5" }, [
                _c("h2", { staticClass: "text-xs-center" }, [
                  _vm._v("Are you sure you want to delete this document?"),
                ]),
                _vm.view
                  ? _c("h4", { staticClass: "text-xs-center grey--text" }, [
                      _vm._v(_vm._s(_vm.view.description)),
                    ])
                  : _vm._e(),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDelete = false
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { outline: "", color: "primary", flat: "" },
                      on: { click: _vm.deleteDoc },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }